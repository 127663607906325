$border-color: #d4d4d4; // neutral-300
$color-gray: #a3a3a3; // neutral-400
$red: #ff0000;

$gutter-width: 24px;

$container-width-xsmall: 640px; // sm
$container-width-small: 768px; // md
$container-width-medium: 1280px; // xl
$container-width-large: 1536px; // 2xl

$footer-height-desktop: 380px;
$footer-height-mobile: 620px;

$header-height-desktop: 95px;
$header-height-mobile: 65px;

$content-width: 780px;

$max-hero-height: 720px;

$font-stack-system: (
  system-ui,
  -apple-system,
  BlinkMacSystemFont,
  "Avenir Next",
  "Avenir",
  "Segoe UI",
  "Lucida Grande",
  "Helvetica Neue",
  "Helvetica",
  "Fira Sans",
  "Roboto",
  "Noto",
  "Droid Sans",
  "Cantarell",
  "Oxygen",
  "Ubuntu",
  "Franklin Gothic Medium",
  "Century Gothic",
  "Liberation Sans",
  sans-serif
);
