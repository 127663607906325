.GeneralCTA {
  overflow: hidden;
}

.GeneralCTA__main {
  padding-top: $gutter-width * 2;
  padding-bottom: $gutter-width * 1.5;
  padding-left: $gutter-width * 2;
  padding-right: $gutter-width * 2;
}

.GeneralCTA__videoContainer {
  position: relative;
  padding-top: 55.6%; /* Player ratio: 100 / (1280 / 720) */
}

.GeneralCTA__video {
  position: absolute;
  top: 0;
  left: 0;
}

.GeneralCTA__captions {
  height: 80px;
  width: 100%;
  position: relative;
  padding-top: $gutter-width*0.25;
  padding-bottom: $gutter-width*0.25;
}

.GeneralCTA__caption {
  font-size: 26px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-align: center;
  padding-left: $gutter-width * 2;
  padding-right: $gutter-width * 2;
}

.GeneralCTA__content {
  font-size: 16px;
  text-align: center;
  padding-bottom: $gutter-width;
  padding-left: $gutter-width * 2;
  padding-right: $gutter-width * 2;
}

.GeneralCTA__button {
}
