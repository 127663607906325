.PageLoadingOverlay {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 40;

  @media print {
    display: none;
  }
}

.PageLoading__content {
}
