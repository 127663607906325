.RangeSlider {
  padding: $gutter-width;

  .rangeslider {
    box-shadow: none;
    margin: 10px 0;
  }

  .rangeslider-horizontal {
    height: 2px;
  }

  .rangeslider .rangeslider__handle {
    width: 18px;
    height: 18px;
    box-shadow: none;
    border: 2px solid #fff;
    &:after {
      display: none;
    }
  }

  .rangeslider-horizontal .rangeslider__fill {
    background-color: #fff;
  }

  .rangeslider__handle-label {
    font-size: 12px;
    position: absolute;
    top: 23px;
    white-space: nowrap;
    font-weight: 500;

    transform: translateX(-50%);
    margin-left: 50%;
  }
}

.RangeSlider--isDisabled {
  opacity: 0.25;
  pointer-events: none;
}
