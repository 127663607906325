.Message {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: desktop) {
    padding: $gutter-width;
  }

  @include mq($from: desktop) {
    padding: $gutter-width;
  }
}

.Message__content {
  max-width: 520px;

  text-align: center;
  border-radius: 4px;
  font-weight: 500;

  @include mq($until: desktop) {
    padding: $gutter-width*0.5 $gutter-width;
  }

  @include mq($from: desktop) {
    padding: $gutter-width $gutter-width * 2;
  }

  a {
    text-decoration: underline;
  }
}
