.Callout {
  padding-left: $gutter-width;
  padding-right: $gutter-width;
}

.Callout__content {
  border-top: 2px solid $border-color;
  padding-top: $gutter-width;
}

.Callout__heading {
  @include mq($until: desktop) {
    font-size: 16px;
    margin-bottom: $gutter-width * 0.5;
  }

  @include mq($from: desktop) {
    font-size: 26px;
    margin-bottom: $gutter-width * 0.5;
  }
}

.Callout__text {
  @include mq($until: desktop) {
    font-size: 16px;
  }

  @include mq($from: desktop) {
    font-size: 26px;
  }
}
