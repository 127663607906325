.CompetitionCountdown {
  position: relative;
  display: flex;
  align-items: center;

  &.CompetitionCountdown--hasEnded {
    opacity: 0.25;
  }
}

.CompetitionCountdown__indicator {
  width: 14px;
  height: 14px;
  background-color: #000;
  border-radius: 50%;

  .CompetitionCountdown--isActive & {
    background-color: #0091ff;
  }
}

.CompetitionCountdown__status {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: $gutter-width*0.25;
  font-weight: 500;
  line-height: 1;
}

.CompetitionCountdown__timer {
  padding-left: 3px;
  padding-right: 3px;
  white-space: nowrap;
  font-variant-numeric: tabular-nums;

  &:after {
    content: '•';
    padding-left: $gutter-width*0.25;
  }
}

.CompetitionCountdown__message {
  padding-left: $gutter-width*0.25;
  white-space: nowrap;

  .CompetitionCountdown--isActive &,
  .CompetitionCountdown--notStarted & {
    color: #0091ff;
  }
}
