.AuthButtons {
  display: flex;
  align-items: center;
  opacity: 0.6;
}

.AuthButtons__button {
  .AuthButton {
    margin-left: $gutter-width * 0.5;
    margin-right: $gutter-width * 0.5;

    @media (hover: hover) {
      &:hover {
        color: #fff;
      }
    }
  }

  &:first-child {
    border-right: 1px solid;
  }
  &:last-child {
    border-right: none;
  }

  &:disabled {
    opacity: 0.5;
  }
}
