@import "swiper/swiper-bundle.min.css";

/*
.swiper-wrapper {
  -webkit-transform-style: preserve-3d;
}

.swiper-slide {
  height: auto;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-button-prev,
.swiper-button-next {
  @apply absolute top-1/2;
  @apply transition-opacity;
  @apply bg-white hover:bg-white/75;
  @apply h-11 w-1/12 rounded-full;
  @apply transition-colors;
  @apply shadow-md;
  @apply bg-no-repeat;
  @apply bg-center;
  @apply after:hidden;
  @apply z-30;
}

.swiper-button-next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M9 18l6-6-6-6'/%3E%3C/svg%3E");

  @apply right-2;
  @apply active:translate-x-[1px];
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M15 18l-6-6 6-6'/%3E%3C/svg%3E");

  @apply left-8;
  @apply active:-translate-x-[1px];
}

.swiper-button-disabled {
  @apply opacity-50;
  @apply pointer-events-none;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-pagination-bullet {
  height: 1px;
  border-radius: 2px;
}

.swiper-pagination-bullet-active {
}

.swiper,
swiper-container {
}

.swiper-horizontal > .swiper-scrollbar {
  @apply h-2;
  @apply z-40;
  @apply bg-zinc-500;
  @apply bottom-0;
  @apply w-auto;
  @apply left-6 lg:left-12;
  @apply right-6 lg:right-12;
}

.swiper-scrollbar-drag {
  @apply bg-current;
}
*/

.swiper-slide {
  @apply h-auto;
}

.swiper-scrollbar-drag {
  @apply bg-white;
}

.swiper-scrollbar.swiper-scrollbar-horizontal {
  @apply w-full;
  @apply h-1.5;
  @apply z-40;
  @apply bottom-0;
  @apply w-auto;
  @apply left-6 lg:left-12;
  @apply right-6 lg:right-12;
  @apply bg-zinc-700;
}

.swiper-scrollbar.swiper-scrollbar-horizontal .swiper-scrollbar-drag {
  @apply transition ease-out;
}

.swiper-scrollbar-left-0 .swiper-scrollbar.swiper-scrollbar-horizontal {
  @apply lg:left-0;
}

.swiper-light .swiper-scrollbar.swiper-scrollbar-horizontal {
  @apply bg-white/5;
}
.swiper-light
  .swiper-scrollbar.swiper-scrollbar-horizontal
  .swiper-scrollbar-drag {
  @apply bg-white/75;
}

.swiper-dark .swiper-scrollbar.swiper-scrollbar-horizontal {
  @apply bg-zinc-950/5;
}
.swiper-dark
  .swiper-scrollbar.swiper-scrollbar-horizontal
  .swiper-scrollbar-drag {
  @apply bg-zinc-950/75;
}
