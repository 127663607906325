@layer utilities {
  .smooth-scroll {
    scroll-behavior: smooth;
  }
  .text-size-adjust {
    -webkit-text-size-adjust: 100%;
  }
  .optimize-legibility {
    text-rendering: optimizeLegibility;
  }

  .direction-rtl {
    direction: rtl;
  }

  .scrollable {
    @apply overflow-y-scroll;
  }

  .scrollable::-webkit-scrollbar-track {
    @apply bg-transparent;
    @apply rounded-full;
  }

  .scrollable::-webkit-scrollbar {
    @apply w-1;
  }

  .scrollable::-webkit-scrollbar-thumb {
    @apply bg-current;
    @apply border-2;
    @apply border-transparent;
    @apply rounded-full;
  }

  .heading-4xl {
    @apply font-medium;
    @apply xl:text-10xl 2xl:text-10xl text-8xl md:text-9xl lg:text-9xl;
  }

  .heading-3xl {
    @apply font-medium;
    @apply text-6xl md:text-7xl md:leading-[0.85] lg:text-8xl lg:leading-[0.9] xl:text-9xl xl:leading-[0.9] 2xl:text-9xl 2xl:leading-[0.85];
  }

  .heading-3xl--spatial {
    @apply heading-3xl;
    @apply font-spatial;
  }

  .heading-3xl--thin {
    @apply heading-3xl;
    @apply font-spatial;
    @apply font-thin;
  }

  .heading-2xl {
    @apply font-medium;
    @apply text-4xl md:text-6xl lg:text-7xl xl:text-7xl 2xl:text-8xl;
  }

  .heading-2xl--spatial {
    @apply heading-2xl;
    @apply font-spatial;
  }

  .heading-2xl--thin {
    @apply heading-2xl;
    @apply font-spatial;
    @apply font-thin;
  }

  .heading-xl {
    @apply font-medium;
    @apply text-4xl md:text-5xl lg:text-6xl 2xl:text-7xl;
  }

  .heading-xl--spatial {
    @apply heading-xl;
    @apply font-spatial;
  }

  .heading-xl--thin {
    @apply font-spatial;
    @apply heading-xl;
    @apply font-thin;
  }

  .heading-lg {
    @apply font-medium;
    @apply text-3xl md:text-4xl lg:text-5xl;
  }

  .heading-lg--thin {
    @apply font-spatial;
    @apply heading-lg;
    @apply font-thin;
  }

  .heading-lg--spatial {
    @apply heading-lg;
    @apply font-spatial;
  }

  .heading-lg--serif {
    @apply heading-lg;
    @apply font-serif;
  }

  .heading-md {
    @apply font-medium;
    @apply text-2xl md:text-3xl lg:text-4xl;
  }

  .heading-md--thin {
    @apply font-spatial;
    @apply heading-md;
    @apply font-thin;
  }

  .heading-md--spatial {
    @apply font-spatial;
    @apply heading-md;
  }

  .heading-sm {
    @apply font-medium;
    @apply text-xl lg:text-2xl xl:text-3xl;
  }

  .heading-sm--thin {
    @apply font-spatial;
    @apply heading-sm;
    @apply font-thin;
  }

  .heading-sm--spatial {
    @apply font-spatial;
    @apply heading-sm;
  }

  .heading-sm--extralight {
    @apply font-spatial;
    @apply heading-sm;
    @apply font-extralight;
  }

  .heading-xs {
    @apply font-medium;
    @apply text-lg lg:text-xl 3xl:text-2xl;
  }

  .heading-xs--thin {
    @apply font-spatial;
    @apply heading-xs;
    @apply font-thin;
  }

  .heading-xs--spatial {
    @apply font-spatial;
    @apply heading-xs;
  }

  .body-text-sm {
    @apply prose prose-sm;
    @apply font-body font-normal;
    @apply text-base;
    > *:first-child {
      @apply mt-0;
    }
    > *:last-child {
      @apply mb-0;
    }
    a {
      @apply underline decoration-zinc-300 decoration-1 underline-offset-4 hover:decoration-zinc-500;
    }
    strong {
      @apply font-semibold;
    }
  }

  .body-text {
    @apply prose;
    @apply font-body font-normal;
    @apply text-lg;
    > *:first-child {
      @apply mt-0;
    }
    > *:last-child {
      @apply mb-0;
    }
    a {
      @apply underline decoration-zinc-300 decoration-1 underline-offset-4 hover:decoration-zinc-500;
    }
    strong {
      @apply font-semibold;
    }
  }

  .body-text-lg {
    @apply prose prose-lg;
    @apply font-body font-normal;
    @apply text-xl;
    @apply max-w-prose;

    > *:first-child {
      @apply mt-0;
    }
    > *:last-child {
      @apply mb-0;
    }
    a {
      @apply underline decoration-zinc-300 decoration-1 underline-offset-4 hover:decoration-zinc-500;
    }
    strong {
      @apply font-semibold;
    }
  }

  .body-text-xl {
    @apply prose prose-xl;
    @apply font-body font-normal;
    @apply text-2xl;
    > *:first-child {
      @apply mt-0;
    }
    > *:last-child {
      @apply mb-0;
    }
    a {
      @apply underline decoration-zinc-300 decoration-1 underline-offset-4 hover:decoration-zinc-500;
    }
    strong {
      @apply font-semibold;
    }
  }

  .menu-arrow::before {
    content: "";
    position: absolute;
    right: 5px;
    bottom: -5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.9);
  }

  .min-h-screen-ex-header {
    @apply min-h-[calc(100vh-(var(--header-height)))];
  }
  .h-screen-ex-header {
    @apply h-[calc(100vh-(var(--header-height)))];
  }
  .min-h-dvh-ex-header {
    @apply min-h-[calc(100dvh-(var(--header-height)))];
  }
  .h-dvh-ex-header {
    @apply h-[calc(100dvh-(var(--header-height)))];
  }
  .pt-header {
    @apply pt-[var(--header-height)];
  }

  .top-header {
    @apply top-[var(--header-height)];
  }
  .top-notification {
    @apply top-[var(--notification-height)];
  }
  .h-header {
    @apply h-[var(--header-height)];
  }

  .h-header-and-notification {
    @apply h-[calc(var(--header-height)+var(--notification-height))];
  }

  .top-header-and-notification {
    @apply top-[calc(var(--header-height)+var(--notification-height))];
  }

  .auth-wrapper {
    @apply flex h-8 items-center rounded-full bg-zinc-800 p-0.5 lg:h-10;
  }

  .auth-trigger {
    @apply flex items-center rounded-full hover:bg-zinc-600 lg:h-9 lg:space-x-2 lg:bg-zinc-700 lg:px-4 lg:pr-3 lg:data-[state=open]:bg-zinc-600;
  }

  .shadow-xs {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  }

  .w-component-sm {
    @apply w-7 lg:w-8;
  }

  .w-component {
    @apply w-9 lg:w-10;
  }

  .w-component-lg {
    @apply w-10 lg:w-12;
  }

  .w-component-2x {
    @apply w-18 lg:w-20;
  }

  .h-component {
    @apply h-9 lg:h-10;
  }

  .h-component-lg {
    @apply h-10 lg:h-12;
  }
  .h-component-md {
    @apply h-8 lg:h-9;
  }

  .h-component-sm {
    @apply h-7 lg:h-8;
  }
}
