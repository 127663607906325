.CurrentRefinements {
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
}

.CurrentRefinements__refinements {
  display: flex;
  flex-wrap: nowrap;

  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.CurrentRefinements__refinement {
}

.CurrentRefinements__toggle {
  @include reset-button;
  display: flex;
  background-color: #000;
  color: #fff;
  align-items: center;
  justify-self: center;
  padding: 8px 10px;
  line-height: 1;
  margin-left: 5px;
  margin-right: 5px;
  height: 35px;
}

.CurrentRefinements__label {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
  white-space: nowrap;
}

.CurrentRefinements__icon {
  align-items: center;
  display: flex;
  justify-self: center;
}
