.AvatarSelector {
}

.AvatarSelector__avatar {
  @include reset-button;
  margin: 0 auto;
  max-width: 420px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.AvatarSelector__toggle {
  margin: 0 auto;
  border-radius: 50%;
  position: relative;

  .Avatar {
    transform: scale(1) translateZ(0);
    transition: transform $easeOutQuad 0.25s;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1) translateZ(0);
      }
    }
  }
}

.AvatarSelector__label {
  padding-top: $gutter-width * 0.5;

  font-size: 16px;

  text-align: center;
}

.AvatarSelector__avatars {
  max-width: 420px;
  margin: 0 auto;
  overflow: hidden;
}

.AvatarSelector__options {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.AvatarSelector__option {
  padding: $gutter-width * 0.25;
}

.AvatarSelector__button {
  @include reset-button;
  border-radius: 50%;

  .Avatar {
    //border: 2px solid #000;
  }
}
