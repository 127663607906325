.SortBy {
  list-style: none;
  padding: 0;
  margin: 0;

  @include mq($until: desktop) {
    padding-bottom: $gutter-width*0.5;
  }

  @include mq($from: desktop) {
    padding-top: $gutter-width;
    padding-bottom: $gutter-width;
  }
}

.SortBy__refinement {
  width: 100%;
}

.SortBy__button {
  @include reset-button;
  display: flex;
  width: 100%;

  align-items: center;

  @include mq($until: desktop) {
    padding: 5px 0;
  }

  @include mq($from: desktop) {
    padding: 5px $gutter-width;
  }
}

.SortBy__icon {
  width: 14px;
  height: 14px;
  border-radius: 14px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  svg {
    opacity: 0;
  }

  .SortBy__refinement--isRefined & {
    background-color: #fff;
    color: #000;

    svg {
      opacity: 1;
    }
  }
}

.SortBy__label {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
}
