.TimerButton {
  display: flex;
  align-items: center;
  position: relative;
}

.TimerButton__button {
  @include reset-button;
  background-color: #000;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  position: relative;
  z-index: 1;

  .TimerButton--isDisabled & {
    background-color: #fff;
    color: #000;
  }
}

.TimerButton__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
}

.TimerButton__icon--isAnimating {
  animation-name: hvr-buzz;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: 15;
}

.TimerButton__label {
  font-size: 16px;
  font-weight: 500;
  padding-right: 20px;
  padding-left: 20px;
  z-index: 1;
  position: relative;
}
