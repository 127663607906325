.FilterDropdown {
  position: relative;

  @include mq($from: desktop) {
    .Filter__dropdown:first-child & {
      border-left: 1px solid $border-color;
    }

    .Filter__dropdown:last-child & {
      border-right: 1px solid $border-color;
    }
  }
}

.FilterDropdown__toggle {
  font-weight: 500;
  background-color: #000;
  color: #fff;
  position: relative;
  z-index: 3;
}

.FilterDropdown__content {
  background-color: #000;
  color: #fff;
  z-index: 2;

  @include mq($until: desktop) {
    position: relative;

    overflow: hidden;
  }

  @include mq($from: desktop) {
    display: block;
    position: absolute;
    top: 64px;
    left: -1px;

    width: calc(100% + 2px);
    border: 2px solid $border-color;
    border-top: 1px solid $border-color;
    overflow: hidden;
    z-index: 1;
  }
}
