@import "~awesome-sass-easing/sass-easing";
@import "~react-rangeslider/lib/index";
@import "~toasted-notes/src/styles";

@import "styles/settings";
@import "styles/media-queries";
@import "styles/animations";

@import "styles/typography";
@import "styles/mixins";

@import "styles/lib/video-js";
@import "styles/lib/tippy";
@import "styles/lib/swiper";

@import "components/Accordian.scss";
@import "components/Account.scss";
@import "components/AccountDetails.scss";
@import "components/Action.scss";
@import "components/ActivateRecipe.scss";
@import "components/AuthButton.scss";
@import "components/AuthButtons.scss";
@import "components/AvatarSelector.scss";
@import "components/Body.scss";
@import "components/Button.scss";
@import "components/Callout.scss";
@import "components/CategoriesCheckboxes.scss";
@import "components/CompetitionCountdown.scss";
@import "components/CompetitionEntry.scss";
@import "components/CuisinesSelector.scss";
@import "components/CurrentRefinements.scss";
@import "components/DeleteMenu.scss";
@import "components/DietaryRequirementsSelector.scss";
@import "components/EmptyState.scss";
@import "components/Entry.scss";
@import "components/EntryContent.scss";
@import "components/EntryHeader.scss";
@import "components/EntryMeta.scss";
@import "components/ErrorMessage.scss";
@import "components/FeaturedVideo.scss";
@import "components/FilterDropdown.scss";
@import "components/FilterSearch.scss";
@import "components/FilterToggle.scss";
@import "components/GeneralCTA.scss";
@import "components/Heading.scss";
@import "components/Hits.scss";
@import "components/Icon.scss";
@import "components/ImageBlock.scss";
@import "components/InlineLoading.scss";
@import "components/InstagramPost.scss";
@import "components/LoadMoreButton.scss";
@import "components/Loading.scss";
@import "components/Logo.scss";
@import "components/MaintenanceMode.scss";
@import "components/MenuHeading.scss";
@import "components/MenuList.scss";
@import "components/Message.scss";
@import "components/Modal.scss";
@import "components/Notification.scss";
@import "components/PageLoading.scss";
@import "components/PageLoadingOverlay.scss";
@import "components/Player.scss";
@import "components/ProductMeta.scss";
@import "components/PurchaseLinks.scss";
@import "components/RangeSlider.scss";
@import "components/Rating.scss";
@import "components/RefinementList.scss";
@import "components/SaveStatus.scss";
@import "components/SelectField.scss";
@import "components/SetPasswordForm.scss";
@import "components/SortBy.scss";
@import "components/Summary.scss";
@import "components/SurpriseMe.scss";
@import "components/TimerButton.scss";
@import "components/TimerDisplay.scss";
@import "components/TimerList.scss";
@import "components/TypesSelector.scss";
@import "components/VideoBlock.scss";
@import "components/VideoPlayer.scss";
@import "components/Ribbon.scss";

.App {
  @include mq($until: desktop) {
    //padding-bottom: $footer-height-mobile;
  }

  @include mq($from: desktop) {
    padding-bottom: $footer-height-desktop;
  }

  &--no-ui {
    padding-bottom: 0;
  }
}

html {
  scroll-margin-top: calc($header-height-desktop + 20px);
}

/*
  Correct a visual quirk with Didomi's script
*/
#didomi-host .didomi-components-radio__option.didomi-components-radio__option {
  display: flex !important;
  align-items: center !important;
  padding: 11.5px !important;
  height: auto !important;
  width: 90px !important;
}

#didomi-host,
#didomi-host .didomi-popup-header,
#didomi-host .didomi-consent-popup-body .didomi-consent-popup-body__title {
  font-family: "Right Grotesk Wide", system-ui, -apple-system,
    BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande",
    "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans",
    "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic",
    "Liberation Sans", sans-serif !important;
}

button:focus {
  border-color: initial;
}
