.Rating__options {
  display: inline-flex;
  flex-grow: 0;
  gap: 4px;

  @include mq($until: desktop) {
  }

  @include mq($from: desktop) {
  }
}

.Rating__option {
  position: relative;

  @include mq($until: desktop) {
    flex-grow: 1;
  }

  @include mq($from: desktop) {
  }
}

.Rating__button {
  @include reset-button;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 15px;
  border: 2px solid #000;
  line-height: 1;
border-radius: 9999px;
  transition: all 0.25s $easeOutQuad;
  text-align: center;

  @media (hover: hover) {
    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}

.Rating__button--isActive {
  background-color: #000;
  color: #fff;
}

.Rating__caption {
  position: absolute;
  text-align: center;
  padding: 10px 5px;
  font-size: 13px;
  left: -5px;
  right: -5px;

  @include mq($until: desktop) {
    display: none;

    .Rating__option:first-child &,
    .Rating__option:last-child & {
      display: block;
    }
  }

  @include mq($from: desktop) {
    display: block;
  }
}
