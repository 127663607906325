.SelectField {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.SelectField__label {
  width: 100%;
}

.SelectField__labelLabel {
  font-size: 16px;
  font-weight: 500;
  padding-top: 3px;
  padding-bottom: 3px;
}

.SelectField__input {
  @include select-input;

  .SelectField--isDisabled & {
    opacity: 0.5;
  }
}

.SelectField__error {
  overflow: hidden;
}

.SelectField__errorMessage {
  padding-top: 10px;
  font-size: 14px;
  color: darken(red, 10%);
}
