@use "sass:math";

@mixin text-button {
  @include reset-button;
  border-bottom: 1px solid $border-color;
  font-size: 16px;
  font-weight: 500;
  margin-left: $gutter-width * 0.25;
  margin-right: $gutter-width * 0.25;
  padding-bottom: math.div($gutter-width, 6);
  padding-top: math.div($gutter-width, 6);
  height: auto;
  width: auto;
  text-align: center;

  &:hover {
    color: #000;
    background-color: transparent;
  }
}

.MenuHeading {
  display: flex;
  max-width: $container-width-large;
  margin: 0 auto;
  align-items: center;
  position: relative;
  transition: color 0.25s $easeOutQuad;

  @include mq($until: tablet) {
    padding: $gutter-width * 1.5;
    padding-left: 0;
    padding-right: 0;
  }

  @include mq($from: tablet) {
    padding: $gutter-width * 2;
  }
}

.MenuHeading__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.MenuHeading__form {
  width: 100%;

  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
  }
}

.MenuHeading__input {
  @include reset-button;

  width: 100%;
  text-align: center;
  cursor: text;
  color: #000;

  &:disabled {
    color: #000;
    -webkit-text-fill-color: #000;
    opacity: 1;
  }
}

.MenuHeading__controls {
  padding: $gutter-width * 0.5;
  width: 100%;
  display: flex;
  justify-content: center;

  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
  }
}

.MenuHeading__control {
}

.MenuHeading__button {
  @include mq($until: tablet) {
    width: 50%;
    margin-left: $gutter-width * 0.5;
    margin-right: $gutter-width * 0.5;
  }

  @include mq($from: tablet) {
  }
}

.MenuHeading__button--save {
  .MenuHeading--isSaving & {
    opacity: 0.5;
  }
}

.MenuHeading__button {
  display: flex;
  justify-content: center;

  .Button {
    &:disabled {
      opacity: 0.5;
    }

    @include mq($until: tablet) {
      margin-top: $gutter-width * 0.5;
    }

    @include mq($from: tablet) {
      @include text-button;
    }
  }
}

.MenuHeading__button--delete {
  @include mq($until: tablet) {
    display: none;
  }
}

.MenuHeading__button--edit {
  .Button {
    @include mq($until: tablet) {
      @include text-button;
    }
  }
}
