.SaveStatus {
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 16px;
}

.SaveStatus__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.SaveStatus__content {
  padding-left: $gutter-width * 0.5;

  a {
  }
}
