.InstagramPost {
  .Body__block & {
    padding-left: $gutter-width;
    padding-right: $gutter-width;

    @include mq($until: tablet) {
      padding-top: $gutter-width*0.5;
      padding-bottom: $gutter-width*0.5;
    }

    @include mq($from: tablet) {
      padding-top: $gutter-width;
      padding-bottom: $gutter-width;
    }
  }
}

.InstagramPost__embed {
  border: 2px solid #000;

  iframe {
    width: 100% !important;
    margin: 0 auto;
    max-width: none !important;
    border: none !important;
    margin: none !important;
  }

  .Body__block & {
    margin: 0 auto;
    max-width: $container-width-small;
  }
}
