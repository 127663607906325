@use "sass:math";

.DeleteMenu {
}

.DeleteMenu__button {
  @include reset-button;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s $easeOutQuad;

  .DeleteMenu--light & {
    border: 2px solid #fff;
    background-color: #000;

    @media (hover: hover) {
      &:hover {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .DeleteMenu--dark & {
    border: 2px solid #000;
    background-color: #fff;

    @media (hover: hover) {
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

.DeleteMenu__tooltip {
  padding: math.div($gutter-width, 3) $gutter-width*0.5;
  max-width: 200px;
}

.DeleteMenu__confirm {
  @include reset-button;

  border-bottom: 1px solid rgba(#fff, 0.25);
  transition: border 0.25s $easeOutQuad;

  @media (hover: hover) {
    &:hover {
      border-bottom: 1px solid rgba(#fff, 0.5);
    }
  }
}
