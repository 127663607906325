/*
	Tailwind base
	*/

@import "tailwindcss/base";
@import "./custom-base-styles.css";

/*
		Tailwind components
		*/

@import "tailwindcss/components";
@import "./custom-components.css";

/*
		Tailwind utilities
		*/

@import "tailwindcss/utilities";
@import "./custom-utilities.css";

button,
input,
optgroup,
select,
textarea {
  @apply bg-transparent;
  @apply text-inherit;
}
