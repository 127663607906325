.ImageBlock {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;

  padding-left: $gutter-width;
  padding-right: $gutter-width;
}

.ImageBlock--showInSidebar {
  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
    visibility: hidden;
    height: 1px;
    overflow: hidden;
    padding: 0;
    opacity: 0;
  }
}

.ImageBlock__image {
  margin: 0 auto;
  max-width: 600px;
}

.ImageBlock__caption {
  font-size: 16px;
  padding-top: 10px;
  margin: 0 auto;
  text-align: center;
  color: #adadad;
}
