.FilterSearch {
  width: 100%;
  height: 100%;
  color: #fff;
}

.FilterSearch__content {
  display: flex;
  position: relative;
  height: 100%;
}

.FilterSearch__icon {
  transform: translateY(50%) scale(1) rotate(0deg);
  transition: transform $easeOutQuad 0.25s;
  position: absolute;

  @include mq($until: desktop) {
    left: $gutter-width;
    top: 11px;
  }

  @include mq($from: desktop) {
    left: $gutter-width * 2;
    top: 14px;
  }
}

.FilterSearch__content {
  @media (hover: hover) {
    &:hover {
      .FilterSearch__icon {
        transform: translateY(50%) scale(1.25) rotate(4deg);
      }
    }
  }
}

.FilterSearch__searchBox {
  width: 100%;
  height: 100%;
}
