.Accordion {
}

.Accordion__panel {
  overflow-y: auto;
  animation: fadein 0.25s $easeOutQuad;

  @include mq($until: desktop) {
    max-height: 33.333vh;
  }

  @include mq($from: desktop) {
  }

  &[data-hidden] {
    display: none;
  }
}

.Accordion__panelContent {
  padding-bottom: $gutter-width;
}

.Accordion__item {
  border-top: 1px solid $border-color;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }
}

.Accordion__button {
  @include reset-button;
  font-weight: 500;
  font-size: 16px;
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  display: flex;
  width: 100%;
}

.Accordian__icon {
  margin-left: auto;
}

.Accordion__button:before {
}

.Accordion__button[aria-expanded="true"]::before,
.Accordion__button[aria-selected="true"]::before {
}

.Accordion__panel {
}

.Accordian__panelContent {
  padding-bottom: $gutter-width;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.Accordian__action {
  padding-top: $gutter-width * 0.5;
}
