@mixin reset-button {
  text-align: left;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: inherit;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
}

@mixin input {
  appearance: none;
  border: 1px solid #ccc;
  height: 40px;
  border-radius: 20px;
  display: block;
  width: 100%;
  padding: 0 20px;
  font-size: 16px;
  font-family: inherit;
  transition: border-color 0.25s;

  &:focus {
    border-color: #999;
    outline: none;
  }
}

@mixin text-input {
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #eeeeee;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-appearance: none;

  &:focus {
    outline: none;
    border-color: #d8d8d8;
  }
}

@mixin select-input {
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  background-color: #f2f2f2;
  border: 1px solid #eeeeee;
  display: block;
  font-size: 16px;
  font-weight: 500;
  height: 50px;
  padding-left: 12px;
  padding-right: 12px;
  -webkit-appearance: none;
  background-image: url('/images/chevron-down.svg') !important;
  background-position: right 10px center !important;

  &:focus {
    outline: none;
    border-color: #d8d8d8;
  }
}

@mixin play-button {
  opacity: 0.8;
  background-color: rgba(0, 0, 0, 0.2);
  border: 2px solid #ffffff;
  border-radius: 50%;
  padding: 0;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s $easeOutQuad;
  cursor: pointer;
  background-position: 57.5% center;
  background-repeat: no-repeat;
  background-image: url('/images/play.svg');

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  @include mq($until: desktop) {
    width: $gutter-width * 3;
    height: $gutter-width * 3;
    background-size: 40%;
    margin-left: $gutter-width * -1.5;
    margin-top: $gutter-width * -1.5;
  }

  @include mq($from: desktop) {
    width: $gutter-width * 4;
    height: $gutter-width * 4;
    background-size: 40%;
    margin-left: $gutter-width * -2;
    margin-top: $gutter-width * -2;
  }
}

@mixin scrollable {
  overflow-y: scroll;

  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 2px;
    background-color: $border-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000;
    border: 2px solid transparent;
  }
}

@mixin hover-grow-rotate {
  transform: scale(1) rotate(0deg);
  transition: transform $easeOutQuad 0.25s;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.25) rotate(4deg);
    }
  }
}

@mixin hover-grow {
  transform: scale(1);
  transition: transform $easeOutQuad 0.25s;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.25);
    }
  }
}

@mixin hover-underline-dark {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: currentColor;
    visibility: hidden;
  }

  @media (hover: hover) {
    &:hover {
      &:before {
        visibility: visible;
      }
    }
  }
}

@mixin hover-underline-light {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -3px;
    left: 0;
    background-color: currentColor;
    visibility: hidden;
  }

  @media (hover: hover) {
    &:hover {
      &:before {
        visibility: visible;
      }
    }
  }
}
