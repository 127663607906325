.ActivateRecipe {
  display: flex;
  align-items: center;

  @include mq($until: desktop) {
  }

  @include mq($from: desktop) {
    font-size: 18px;
  }
}

.ActivateRecipe__button {
  @include reset-button;
  border-radius: 50%;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  background-color: #000000;
  justify-content: center;
  align-items: center;
  transition: transform 0.25s $easeOutQuad;
  animation-duration: 1s;
  animation-fill-mode: both;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;

  .ActivateRecipe--animation--pulse & {
    animation-name: pulse;
  }

  @media (hover: hover) {
    &:hover {
      transform: scale(1.1);
    }
  }

  @include mq($until: desktop) {
    width: 58px;
    height: 58px;
  }

  @include mq($from: desktop) {
    width: 84px;
    height: 84px;
  }
}

.ActivateRecipe__label {
  color: #fff;
  font-family: "Right Grotesk Spatial", $font-stack-system;
  font-weight: 900;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;

  @include mq($until: desktop) {
    font-size: 22px;
  }

  @include mq($from: desktop) {
    font-size: 30px;
  }
}

.ActivateRecipe__content {
  @include mq($until: desktop) {
    padding-left: $gutter-width * 0.5;
  }

  @include mq($from: desktop) {
    padding-left: $gutter-width;
  }
}
