.ribbon {
    --ribbon-colour: #fff;
    position: absolute;
    inset: 0 0 auto auto;
    background: var(--ribbon-colour);
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
    box-shadow: 0 0 0 999px var(--ribbon-colour);
    clip-path: inset(0 -100%);
    pointer-events: none;
  }