.MaintenanceMode {
  background-color: #000;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $gutter-width;
}

.MaintenanceMode__content {
  max-width: 320px;
  text-align: center;
}

.MaintenanceMode__logotype {
  height: $gutter-width * 3;
  margin-top: $gutter-width;
  margin-bottom: $gutter-width;

  .Logotype {
    height: 100%;

    svg {
      height: 100%;
    }
  }
}

.MaintenanceMode__message {
  padding-top: $gutter-width*0.5;
  padding-bottom: $gutter-width*0.5;

  a {
    text-decoration: underline;
  }
}
