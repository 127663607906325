.Player {
  .Body__block & {
    padding-left: $gutter-width;
    padding-right: $gutter-width;

    @include mq($until: tablet) {
      padding-top: $gutter-width * 0.5;
      padding-bottom: $gutter-width * 0.5;
    }

    @include mq($from: tablet) {
      padding-top: $gutter-width;
      padding-bottom: $gutter-width;
    }
  }
}

.Player__embed {
  border: 2px solid #000;
  border-radius: 14px;
  overflow: hidden;

  .Body__block & {
    margin: 0 auto;
    max-width: $container-width-small;
  }
}
