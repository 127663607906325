.VideoPlayer {
  background-color: #000;

  @include mq($until: desktop) {
    padding: 0 $gutter-width;
  }

  @include mq($from: desktop) {
    padding: 0 $gutter-width * 2;
  }
}

.VideoPlayer__content {
  max-width: $container-width-large;
  margin: 0 auto;

  position: relative;
  display: block;
  background-color: #000;
  width: 100%;

  @include mq($until: desktop) {
  }

  @include mq($from: desktop) {
  }
}

.VideoPlayer__panels {
  display: flex;

  @include mq($until: tablet) {
    flex-direction: column;
  }

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.VideoPlayer__panel {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;

  @include mq($until: tablet) {
    width: 100%;
  }

  @include mq($from: tablet) {
    width: 50%;
  }
}

.VideoPlayer__video {
  padding-top: 100%;
  width: 100%;
}

.VideoPlayer__video video-js {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.VideoPlayer__info {
  color: #fff;
  background-color: #101010;
  display: flex;
  flex-direction: column;
  height: 100%;

  @include mq($until: desktop) {
  }

  @include mq($from: desktop) {
    padding: $gutter-width;
  }
}

.VideoPlayer__header {
  padding: $gutter-width;
  display: flex;
  align-items: center;
}

.VideoPlayer__action {
  margin-left: auto;
  font-size: 20px;
}

.VideoPlayer__heading {
  text-transform: none;
}

.VideoPlayer__playlist {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  @include mq($until: desktop) {
    padding: $gutter-width;
    padding-top: 0;
    padding-bottom: 0;
    height: 33.333vh;
    @include scrollable;
  }

  @include mq($from: desktop) {
    padding: $gutter-width;
  }
}

.VideoPlayer__playlistItem {
  width: 100%;
}

.VideoPlayer__playlistSelect {
  @include reset-button;
  width: 100%;
  border-top: 2px solid #000;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid #000;
  }
}

.VideoPlayer__playlistSelect--isActive {
  opacity: 1;
}

.VideoPlayer__title {
  line-height: 1.25;
}

.VideoPlayer__icon {
  margin-left: auto;
}

.VideoPlayer__title,
.VideoPlayer__icon {
  opacity: 0.25;
  transition: opacity 0.25s $easeOutQuad;

  .VideoPlayer__playlistItem--isActive & {
    opacity: 1;
  }

  @media (hover: hover) {
    .VideoPlayer__playlistItem:hover & {
      opacity: 1;
    }
  }
}
