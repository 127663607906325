.TimerDisplay {
  border: 2px solid #000;
  border-radius: $gutter-width;
  font-weight: 500;
  white-space: nowrap;
  font-size: 16px;

  overflow: hidden;
  line-height: 1;
  min-width: 62px;
  text-align: center;
  transition: all 0.25s $easeOutQuad;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TimerDisplay--isLarge {
  font-size: 22px;
}

.TimerDisplay--isFinished {
  border-color: $red;
  background-color: $red;
  color: #fff;
  min-width: auto;
}

.TimerDisplay__time {
  width: $gutter-width * 1.75;
  text-align: center;
  font-variant-numeric: tabular-nums;

  .TimerDisplay--isLarge & {
    width: $gutter-width * 2.5;
  }
}

.TimerDisplay__action {
  @include reset-button;
  width: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TimerDisplay__display {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 8px;

  .TimerDisplay--isLarge & {
    padding: 12.5px 12.5px;
  }
}

.TimerDisplay__finished {
  @include reset-button;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;

  .TimerDisplay--isLarge & {
    height: 43px;
    width: 43px;
  }
}

.TimerDisplay__finishedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
  backface-visibility: hidden;
  will-change: transform;
}

.TimerDisplay__finishedIcon--isAnimating {
  animation-name: hvr-buzz;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: 15;
}
