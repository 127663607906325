h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

@mixin h1 {
  font-weight: 500;

  @include mq($until: tablet) {
    font-size: 22px;
    line-height: 1.135;
  }

  @include mq($from: tablet, $until: desktop) {
    font-size: 30px;
    line-height: 1.135;
  }

  @include mq($from: desktop) {
    font-size: 48px;
    line-height: 1;
  }
}

@mixin h2 {
  @include mq($until: tablet) {
    font-size: 22px;
    line-height: 1.135;
  }

  @include mq($from: tablet, $until: desktop) {
    font-size: 30px;
    line-height: 1.135;
  }

  @include mq($from: desktop) {
    font-size: 48px;
    line-height: 1.125;
  }
}

@mixin h3 {
  font-weight: 500;

  @include mq($until: desktop) {
    font-size: 20px;
    line-height: 1.3;
  }

  @include mq($from: desktop) {
    font-size: 29px;
    line-height: 1.2;
  }
}

@mixin h4 {
  font-weight: 500;

  @include mq($until: desktop) {
    font-size: 18px;
    line-height: 1.2;
  }

  @include mq($from: desktop) {
    font-size: 25px;
    line-height: 1.1;
  }
}

@mixin h5 {
  font-weight: 500;

  @include mq($until: desktop) {
    line-height: 1.2;
  }

  @include mq($from: desktop) {
    font-size: 21px;
    line-height: 1.1;
  }
}

@mixin jjannon {
  font-family: "JJannon";
  font-weight: 400;
  letter-spacing: -0.015em;
}

@mixin body-text {
  @include jjannon();

  @include mq($until: tablet) {
    font-size: 18px;
    line-height: 1.4;
  }

  @include mq($from: tablet) {
    font-size: 20px;
    line-height: 1.4;
  }

  strong {
    font-weight: 700;
  }
}

@mixin body-text--large {
  @include body-text;

  @include mq($until: tablet) {
    font-size: 20px;
    line-height: 1.4;
  }

  @include mq($from: tablet) {
    font-size: 22px;
    line-height: 1.4;
  }
}

@mixin body-text--small {
  @include body-text;

  @include mq($until: tablet) {
    font-size: 16px;
    line-height: 1.4;
  }

  @include mq($from: tablet) {
    font-size: 18px;
    line-height: 1.4;
  }
}
