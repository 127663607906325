.Hits {
}

.Hits__pagination {
  display: flex;
  justify-content: center;

  &.Hits__pagination--header {
    padding-bottom: $gutter-width * 2;
  }

  &.Hits__pagination--footer {
    padding-top: $gutter-width * 2;
    padding-bottom: $gutter-width * 4;
  }
}

.Hits__loadMore,
.Hits__loadPrevious {
  @include reset-button;

  font-size: 22px;
  color: #595959;
  text-align: center;
  margin: 0 auto;
  transition: color 0.25s $easeOutQuad;

  @media (hover: hover) {
    &:hover {
      color: #000;
    }
  }
}
