.AccountDetails {
}

.AccountDetails__attribute {
  padding-top: $gutter-width * 0.5;
}

.AccountDetails__header {
  display: flex;
}

.AccountDetails__label {
  font-size: 16px;
  font-weight: 500;
}

.AccountDetails__toggle {
  @include reset-button;
  margin-left: auto;
  font-size: 16px;
  font-weight: 500;

  border-bottom: 1px solid $border-color;

  &:hover {
    border-bottom: 1px solid #000;
  }
}
