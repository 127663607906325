.AuthButton {
  @include reset-button;
  display: block;
  font-size: 16px;
  font-weight: 500;
  @include hover-underline-light;

  @include mq($until: tablet) {
    line-height: 1.2;
  }

  @include mq($from: tablet) {
    line-height: 1;
  }

  @media (hover: hover) {
    &:hover {
      color: #fff;
    }
  }
}
