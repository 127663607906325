.FeaturedVideo {
  position: relative;
  overflow: hidden;

  @include mq($until: tablet) {
  }
}

.FeaturedVideo__content {
  max-width: $container-width-large;
  margin: 0 auto;

  position: relative;
  display: block;
  width: 100%;

  @include mq($until: tablet) {
    padding-bottom: $gutter-width * 2;
  }

  @include mq($from: tablet) {
    padding-bottom: $gutter-width * 2;
  }
}

.FeaturedVideo__panels {
  display: flex;

  @include mq($until: tablet) {
    flex-direction: column;
  }

  @include mq($from: tablet) {
    flex-direction: row;
  }
}

.FeaturedVideo__panel {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;

  @include mq($until: tablet) {
    width: 100%;
  }

  @include mq($from: tablet) {
    width: 50%;
  }
}

.FeaturedVideo__header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #fff;
}

.FeaturedVideo__heading {
  @extend h1;
  text-align: center;
  padding: $gutter-width * 2;
}

.FeaturedVideo__video {
  padding-top: 100%;
  width: 100%;
}

.FeaturedVideo__video video-js {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
