.Body {
}

.Body__block {
}

.Body__block--callout {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
}

.Body__headingWrapper {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
}

.Body__heading {
  max-width: 700px;
  margin: 0 auto;
  text-transform: none;
  @include h3;
}
