.CategoriesCheckboxes__categories {
  display: flex;
  flex-wrap: wrap;
}

.CategoriesCheckboxes--isScrollable {
  .CategoriesCheckboxes__categories {
    max-height: 240px;
    @include scrollable;
  }
}

.CategoriesCheckboxes__category {
  width: 50%;
}

.CategoriesCheckboxes__label {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
  line-height: 1;
  cursor: pointer;
}

.CategoriesCheckboxes__labelLabel {
  padding-left: $gutter-width * 0.5;
}

.CategoriesCheckboxes__checkbox {
  display: flex;
  align-items: center;
}
