.Modal {
}

.Modal__overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 60;
  backdrop-filter: blur(0px);
  opacity: 0;
  transition: opacity 0.25s $easeOutQuad, backdrop-filter 0.25s $easeOutQuad;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: desktop) {
    padding: $gutter-width;
  }

  @include mq($from: desktop) {
    padding: $gutter-width;
  }
}

.Modal__overlay--afterOpen {
  opacity: 1;
  backdrop-filter: blur(5px);
}

.Modal__overlay--beforeClose {
  opacity: 0;
}

.Modal__content {
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scale(0.92);
  max-height: 100%;
  height: auto;
  outline: none;
  opacity: 0;
  transition: opacity 0.25s $easeOutQuad, transform 0.25s $easeOutQuad;
  border-radius: 12px;

  overflow-y: scroll;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border: 1px solid transparent;
  }

  @include mq($until: tablet) {
    padding: $gutter-width;
  }

  @include mq($from: tablet) {
    padding: $gutter-width * 2;
    max-width: 500px;
  }
}

.Modal__content--afterOpen {
  opacity: 1;
  transform: scale(1);
}

.Modal__content--beforeClose {
  opacity: 0;
  transform: scale(0.92);
}

.Modal__heading {
  text-transform: none;
  text-align: center;
}

.Modal__header {
  margin: 0 auto;
  max-width: 380px;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.Modal__headerContent {
  font-size: 16px;
  text-align: center;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.Modal__close {
  @include reset-button;
  position: absolute;

  width: $gutter-width * 2;
  height: $gutter-width * 2;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: desktop) {
    top: 0;
    right: 0;
  }

  @include mq($from: desktop) {
    top: $gutter-width * 0.5;
    right: $gutter-width * 0.5;
  }
}
