.RefinementList {
  @include mq($until: desktop) {
    padding-bottom: $gutter-width;
  }

  @include mq($from: desktop) {
  }
}

.RefinementList__refinements {
  @include scrollable;

  @include mq($until: desktop) {
    max-height: 50vh;
  }

  @include mq($from: desktop) {
    max-height: 350px;

    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }
}

.RefinementList__refinement {
  width: 100%;
  overflow: hidden;
}

.RefinementList__button {
  @include reset-button;
  display: flex;
  width: 100%;
  align-items: center;

  @include mq($until: desktop) {
    padding: 7px 0;
  }

  @include mq($from: desktop) {
    padding: 7px $gutter-width;
  }
}

.RefinementList__icon {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $gutter-width * 0.5;

  svg {
    opacity: 0;
  }

  .RefinementList__refinement--isRefined & {
    background-color: #fff;
    color: #000;

    svg {
      opacity: 1;
    }
  }
}

.RefinementList__label {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
}
