.video-js .vjs-big-play-button {
  z-index: 100;
  @include play-button;

  .vjs-icon-placeholder {
    display: none;
  }
}

.video-js.vjs-has-started.vjs-paused .vjs-big-play-button {
  display: flex;
}

.bc-iframe,
.bc-iframe body,
.bc-player-default_default,
.bc-player-default_default .vjs-poster {
  background-color: #000;
}

.video-js.vjs-mouse .vjs-big-play-button:active,
.video-js.vjs-mouse .vjs-big-play-button:focus,
.video-js.vjs-mouse:active .vjs-big-play-button,
.video-js.vjs-mouse:hover .vjs-big-play-button {
  background-color: #000;
}

.video-js .vjs-control-bar {
  font-size: 12px;
  font-family: 'ModernEra', $font-stack-system;
}

.video-js .vjs-time-tooltip {
  font-family: 'ModernEra', $font-stack-system;
}

.video-js .vjs-volume-level {
  background-color: #fff;
}

.video-js .vjs-volume-vertical {
  background-color: rgba(#d8d8d8, 0.2);
}

.video-js .vjs-load-progress div {
  background-color: rgba(#d8d8d8, 0.2);
}

.video-js .vjs-play-progress.vjs-slider-bar {
  background-color: #d8d8d8;
}

.video-js .vjs-dock-text {
  display: none !important;
}

.video-js .vjs-slider {
  background-color: transparent;
}

.video-js .vjs-control:focus,
.video-js .vjs-control:focus:before,
.video-js .vjs-control:hover:before {
  text-shadow: none;
  outline: none;
}
