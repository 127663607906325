.SurpriseMe {
  animation-duration: 1s;
  animation-fill-mode: both;
  visibility: hidden;
}

.SurpriseMe--initialAnimation {
  visibility: visible;
}

.SurpriseMe--initialAnimation--jackInTheBox {
  animation-name: jackInTheBox;
}

.SurpriseMe--initialAnimation--bounceInRight {
  animation-name: bounceInRight;
}

.SurpriseMe--initialAnimation--zoomInRight {
  animation-name: zoomInRight;
}

.SurpriseMe--attentionAnimation--tada {
  animation-name: tada;
}

.SurpriseMe--attentionAnimation--rubberBand {
  animation-name: rubberBand;
}

.SurpriseMe--attentionAnimation--jello {
  animation-name: jello;
}
