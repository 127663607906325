.Heading {
  display: flex;
  position: relative;
  transition: color 0.25s $easeOutQuad;

  @include mq($until: tablet) {
    padding-left: $gutter-width * 1.5;
    padding-right: $gutter-width * 1.5;
  }

  @include mq($from: tablet) {
    padding-left: $gutter-width * 2;
    padding-right: $gutter-width * 2;
  }
}

.Heading--spaceAbove--none {
  padding-top: 0;
}
.Heading--spaceAbove--small {
  padding-top: $gutter-width;
}
.Heading--spaceAbove--medium {
  padding-top: $gutter-width * 2;
}
.Heading--spaceAbove--large {
  padding-top: $gutter-width * 3;
}
.Heading--spaceAbove--xLarge {
  padding-top: $gutter-width * 4;
}

.Heading--spaceBelow--none {
  padding-bottom: 0;
}
.Heading--spaceBelow--small {
  padding-bottom: $gutter-width;
}
.Heading--spaceBelow--medium {
  padding-bottom: $gutter-width * 2;
}
.Heading--spaceBelow--large {
  padding-bottom: $gutter-width * 3;
}
.Heading--spaceBelow--xLarge {
  padding-bottom: $gutter-width * 4;
}

.Heading__content {
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: $container-width-small;
  margin: 0 auto;
}

.Heading__heading {
  text-align: center;
  @include h4;

  .Heading--withSubHeading.Heading--style--border & {
    margin-bottom: $gutter-width * 0.5;
  }
}

.Heading__headingLabel,
.Heading__link {
  border: 2px solid transparent;
  line-height: 1;
  display: block;
  position: relative;
  z-index: 1;
  border-radius: $gutter-width * 2;

  .Heading--style--border & {
    border-color: #000;
    display: block;
    background-color: #000;
    color: #fff;

    @include mq($until: tablet) {
      padding: 11px $gutter-width;
    }

    @include mq($from: tablet) {
      padding: 15px $gutter-width * 1.5;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #000;
        color: #fff;
      }
    }
  }
}

// Hover animations

.Heading__link {
  transition: all 0.25s $easeOutQuad;
  transform: translateZ(0);
  vertical-align: middle;
}

@media (hover: hover) {
  .Heading__link:hover {
    .Heading--hoverAnimation--none & {
    }

    .Heading--hoverAnimation--growRotate & {
      transform: scale(1.1) rotate(4deg);
    }

    .Heading--hoverAnimation--rotate & {
      transform: rotate(4deg);
    }

    .Heading--hoverAnimation--bob & {
      animation-name: hvr-bob-float, hvr-bob;
      animation-duration: 0.3s, 1.5s;
      animation-delay: 0s, 0.3s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate;
    }

    .Heading--hoverAnimation--hang & {
      animation-name: hvr-hang-sink, hvr-hang;
      animation-duration: 0.3s, 1.5s;
      animation-delay: 0s, 0.3s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate;
    }

    .Heading--hoverAnimation--buzzOut & {
      animation-name: hvr-buzz-out;
      animation-duration: 0.75s;
      animation-timing-function: linear;
      animation-iteration-count: 1;
    }

    .Heading--hoverAnimation--grow & {
      transform: scale(1.1);
    }

    .Heading--hoverAnimation--float & {
      transform: translateY(-8px);
    }

    .Heading--hoverAnimation--sink & {
      transform: translateY(8px);
    }
  }
}
