.VideoBlock {
  padding: $gutter-width;
  padding-top: 0;
}

.VideoBlock__video {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 700px;

  .Video {
    width: 100%;
    padding-top: 100%;
    position: relative;

    video-js {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}

.VideoBlock--inline {
  padding-left: $gutter-width;
  padding-right: $gutter-width;

  .VideoBlock__image {
    max-width: 850px;
  }
}

.VideoBlock--fullWidth {
  .VideoBlock__image {
    max-height: 66.666vh;
    overflow: hidden;
  }
}

.VideoBlock__caption {
  font-size: 16px;
  padding-top: 10px;
  margin: 0 auto;
  text-align: center;
}
