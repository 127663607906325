.EntryHeader {
  @include mq($until: tablet) {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

  @include mq($from: tablet) {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }
}

.EntryHeader--dark {
  background-color: #000;
}

.EntryHeader--light {
  background-color: #fff;
}

.EntryHeader__content {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-width: $container-width-large;
  margin: 0 auto;

  .EntryHeader--light & {
    border-bottom: 2px solid $border-color;
    color: #000;
  }

  .EntryHeader--dark & {
    color: #fff;
  }
}

.EntryHeader__heading {
  max-width: $container-width-small;
  margin: 0 auto;
  @include h2;

  @include mq($until: tablet) {
    padding-top: $gutter-width * 1.5;
    padding-bottom: $gutter-width * 1.5;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

  @include mq($from: tablet) {
    padding-top: $gutter-width * 5;
    padding-bottom: $gutter-width * 3;
  }
}

.EntryHeader__meta {
  text-align: center;
  margin-bottom: $gutter-width;
}

.EntryHeader__summary {
  max-width: $container-width-small;
  margin: 0 auto;

  .EntryHeader--dark & {
    color: #fff;
  }

  .EntryHeader--light & {
    color: #000;
  }

  @include mq($until: tablet) {
    font-size: 16px;

    padding-bottom: $gutter-width * 1.5;
  }

  @include mq($from: tablet) {
    font-size: 30px;
    font-weight: 500;
    padding-bottom: $gutter-width * 2;
  }
}
