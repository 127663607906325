.EntryContent__columns {
  display: flex;
  max-width: $container-width-large;
  margin: 0 auto;

  @include mq($until: tablet) {
    flex-direction: column;
  }

  @include mq($from: tablet) {
    flex-direction: row;
    padding-top: $gutter-width * 2;
    padding-bottom: $gutter-width * 2;
  }
}

.EntryContent__main,
.EntryContent__sidebar {
  width: 50%;
}

.EntryContent__sidebar {
  order: 1;

  @include mq($until: tablet) {
    width: 100%;
    padding-top: $gutter-width;
    padding-bottom: $gutter-width * 0.5;
    order: 2;
    display: none;
  }

  @include mq($from: tablet) {
    width: 50%;
    order: 1;
    border-right: 1px solid $border-color;
    padding-right: $gutter-width * 2;
  }
}

.EntryContent__main {
  @include mq($until: tablet) {
    width: 100%;
    order: 1;
    padding-bottom: $gutter-width * 2;
  }

  @include mq($from: tablet) {
    order: 2;
    width: 50%;
    border-left: 1px solid $border-color;
    padding-left: $gutter-width;
  }
}

.EntryContent__body {
  @include mq($until: tablet) {
    padding-bottom: $gutter-width * 1;
  }

  @include mq($from: tablet) {
    padding-bottom: $gutter-width * 2;
  }
}

.EntryContent__summary {
}

.EntryContent__callout {
  @include mq($until: tablet) {
    padding-bottom: $gutter-width * 2;
  }

  @include mq($from: tablet) {
    padding-bottom: $gutter-width * 2.5;
  }
}

.EntryContent__recipe {
  padding-left: $gutter-width;
  padding-right: $gutter-width;
}

.EntryContent__recipeLink {
  display: block;
  border-top: 2px solid $border-color;
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;

  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
    font-size: 26px;
  }
}

.EntryContent__caption {
  font-size: 16px;
  color: #adadad;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
  text-align: center;
}

.EntryContent__caption--forMobile {
  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
    display: none;
  }
}
