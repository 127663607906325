.Account {
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  padding-bottom: $gutter-width * 4;
}

.Account__avatar {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
}

.Account__header {
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  display: flex;
  flex-direction: column;
}

.Account__title {
  text-align: center;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.Account__heading {
  text-align: center;
  padding-left: $gutter-width;
  padding-right: $gutter-width;
}

.Account__details {
  max-width: 420px;
  margin: 0 auto;
}

.Account__location {
  max-width: 420px;
  margin: 0 auto;
  padding-bottom: $gutter-width * 2;
  margin-bottom: $gutter-width * 2;
  border-bottom: 1px solid $border-color;
}

.Account__locationSelector {
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.Account__gender {
  max-width: 420px;
  margin: 0 auto;

  padding-bottom: $gutter-width * 2;
  margin-bottom: $gutter-width * 2;
  border-bottom: 1px solid $border-color;
}

.Account__newsletter {
  max-width: 420px;
  margin: 0 auto;

  padding-bottom: $gutter-width * 2;
}

.Account__genderSelector {
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}

.Account__categories {
  max-width: 420px;
  margin: 0 auto;

  padding-bottom: $gutter-width * 2;
}

.Account__accountAction {
  max-width: 420px;
  margin: 0 auto;
  padding-top: $gutter-width * 0.5;
  padding-bottom: $gutter-width * 0.5;
}
