.Button--light {
  border: 2px solid #000;
  color: #fff;
  background-color: #000;

  @media (hover: hover) {
    &.Button--hoverEffect--none:hover {
      color: #000;
      background-color: #fff;
      border-color: #000;
    }
  }

  &.Button--withBorder {
    border: 2px solid #fff;
  }
}

.Button--wrapText {
  white-space: normal;
  height: auto;
  padding: $gutter-width * 0.5;
}

// animation styles

.Button--hoverEffect--bounceToBottom {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
  overflow: hidden;
}
.Button--hoverEffect--bounceToBottom:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  transform: scaleY(0);
  transform-origin: 50% 0;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}

@media (hover: hover) {
  .Button--hoverEffect--bounceToBottom:hover,
  .Button--hoverEffect--bounceToBottom:focus,
  .Button--hoverEffect--bounceToBottom:active {
    color: white;
  }
  .Button--hoverEffect--bounceToBottom:hover:before,
  .Button--hoverEffect--bounceToBottom:focus:before,
  .Button--hoverEffect--bounceToBottom:active:before {
    transform: scaleY(1);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  }
}
