.PageLoading {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 20;
}

.PageLoading__content {
  .Logo {
    height: 45px;
  }
}

.PageLoading__animation {
}
