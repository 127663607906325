@import '~tippy.js/dist/tippy';
@import '~tippy.js/dist/svg-arrow';

.tippy-box {
  background-color: #000;
  border-radius: 3px;
}

.tippy-content {
  padding: 0;
  background-color: #000;
  border: 2px solid #191919;
  border-radius: 2px;
  font-size: 14px;
}

.ingredient-options .tippy-content,
.ingredient-options.tippy-box {
  border-radius: 12px;
}

.tippy-box {
  & > .tippy-arrow {
    z-index: 1;
    color: #191919;

    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      display: block;
    }
  }

  &[data-placement^='bottom'] > .tippy-arrow {
    border-bottom-color: #191919;
    top: -1px;

    &:after {
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent #000 transparent;
      bottom: 13px;
      left: 0px;
    }
  }

  &[data-placement^='top'] > .tippy-arrow {
    border-top-color: #191919;
    bottom: -1px;

    &:after {
      border-width: 8px 8px 0 8px;
      border-color: #000 transparent transparent transparent;
      top: 13px;
      left: 0px;
    }
  }
}
