.MenuList {
  color: #fff;
}

.MenuList__toggle {
  @include reset-button;
  display: flex;
  align-items: center;
  width: 100%;

  border-bottom: 2px solid rgba($border-color, 0.125);

  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1;

  &:focus {
    border-bottom: 2px solid rgba($border-color, 0.125);
  }
}
