.SetPasswordForm {
  padding-left: $gutter-width;
  padding-right: $gutter-width;
  padding-bottom: $gutter-width * 4;
}

.SetPasswordForm__form {
  max-width: 420px;
  margin: 0 auto;
}
