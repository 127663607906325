@use "sass:math";

.TimerList {
  position: fixed;
  left: $gutter-width;
  bottom: $gutter-width;
  z-index: 1000;
  border: 2px solid #000;
  border-radius: $gutter-width;
  overflow: hidden;

  @include mq($until: desktop) {
    right: $gutter-width;
  }

  @include mq($from: desktop) {
    min-width: 280px;
  }

  &.TimerList--isFinished {
    border-color: $red;
  }
}

.TimerList--isActive {
}

.TimerList__toggle {
  @include reset-button;
  width: 100%;
}

.TimerList__header {
  @include reset-button;
  display: flex;
  background-color: #000;
  color: #fff;
  align-items: center;
  padding: 10px;
  width: 100%;

  .TimerList--isFinished & {
    background-color: $red;
  }
}

.TimerList__headerLabel {
  font-size: 16px;
  font-weight: 500;
  padding-left: math.div($gutter-width, 3);
  padding-right: math.div($gutter-width, 3);
  font-variant-numeric: tabular-nums;
}

.TimerList__headerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TimerList__headerIcon--isAnimating {
  animation-name: hvr-buzz;
  animation-duration: 0.15s;
  animation-timing-function: linear;
  animation-iteration-count: 15;
}

.TimerList__timers {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.TimerList__timer {
  border-bottom: 2px solid $border-color;
  font-size: 16px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:last-child {
    border-bottom: 0;
  }
}

.TimerList__display {
  padding: 10px;
}

.TimerList__name {
  padding: 10px;
  font-weight: 500;
}

.TimerList__actions {
  margin-left: auto;
  padding: 10px;
}

.TimerList__delete {
  @include reset-button;
}

.TimerList__headerAction {
  @include reset-button;
  margin-left: auto;
}
