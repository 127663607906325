@use "sass:math";

.EntryMeta {
  font-size: 16px;

  @include mq($until: tablet) {
  }

  @include mq($from: tablet) {
  }

  .EntryMeta--light & {
    color: #717171;
  }

  .EntryMeta--dark & {
    color: #595959;
  }
}

.EntryMeta__readTime,
.EntryMeta__byline,
.EntryMeta__date {
  display: inline-block;
  position: relative;
}

.EntryMeta__readTime {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.EntryMeta__byline {
  display: inline;

  p {
    margin: 0;
    display: inline;
  }

  span {
    @include hover-underline-light;
  }
}

.EntryMeta__date {
  display: inline;
  white-space: nowrap;
}

.EntryMeta__readTime {
}
