.InlineLoading {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: $gutter-width * 2;
  padding-right: $gutter-width * 2;
  font-size: 16px;
  font-weight: 500;
  height: $gutter-width * 2;
  align-items: center;
}

.InlineLoading--alignLeft {
  justify-content: flex-start;
}

.InlineLoading__label {
  padding-left: $gutter-width*0.5;
  color: #999;
}
