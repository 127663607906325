.Action {
  @include reset-button;
  display: flex;
  align-items: center;
}

.Action__label {
  margin-right: 5px;
  @include hover-underline-light;
}

.Action__icon {
  width: 20px;
  height: 20px;
  position: relative;
  transition: transform 0.25s $easeOutQuad;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Action {
  @media (hover: hover) {
    &:hover {
      .Action__icon {
        transform: translateX(3px);
      }
    }
  }
}
