.Notification {
  background-color: #000;
  display: flex;
}

.Notification__content {
  display: flex;
  align-items: center;
}

.Notification__message {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding-left: $gutter-width * 1.5;

  a {
    border-bottom: 1px solid rgba(#fff, 0.25);
    transition: border 0.25s $easeOutQuad;

    @media (hover: hover) {
      &:hover {
        border-bottom: 1px solid rgba(#fff, 0.5);
      }
    }
  }
}

.Notification__closeButton {
  @include reset-button;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: $gutter-width * 3;
  height: $gutter-width * 3;
}
