.PurchaseLinks {
}

.PurchaseLinks__links {
  display: flex;
  flex-direction: column;
}

.PurchaseLinks__link {
  display: flex;
  padding-top: $gutter-width;
  padding-bottom: $gutter-width;
  align-items: center;
}

.PurchaseLinks__block {
  border-bottom: 2px solid $border-color;

  &:last-child {
    border: none;
  }
}

.PurchaseLinks__logo {
  padding-right: $gutter-width;
  display: flex;
  align-items: center;
  max-width: calc(100% - 90px);
}

.PurchaseLinks__img {
  max-width: 100%;
  max-height: 30px;
}

.PurchaseLinks__heading {
  padding-top: $gutter-width * 2;
  padding-bottom: $gutter-width;
}

.PurchaseLinks__button {
  @include reset-button;
  border: 2px solid #000;
  color: #000;
  background-color: #fff;
  margin-left: auto;

  font-weight: 500;

  padding-left: $gutter-width;
  padding-right: $gutter-width;

  transition: color 0.25s $easeOutQuad, background-color 0.25s $easeOutQuad,
    opacity 0.75s $easeOutQuad;
  white-space: nowrap;
  display: flex;
  align-items: center;

  @include mq($until: desktop) {
    padding-left: $gutter-width - 5px;
    padding-right: $gutter-width - 5px;
    height: 45px;
  }

  @include mq($from: desktop) {
    padding-left: $gutter-width;
    padding-right: $gutter-width;
    height: 50px;
  }

  @media (hover: hover) {
    .PurchaseLinks__link:hover & {
      color: #fff;
      background-color: #000;
      border-color: #000;
    }
  }
}

.PurchaseLinks__buttonLabel {
}
