.CompetitionEntry__content {
  margin: 0 auto;
  max-width: $container-width-small;
  display: flex;
  justify-content: center;

  padding-top: $gutter-width * 2;
  padding-bottom: $gutter-width * 2;
}

.CompetitionEntry__error {
  text-align: center;
  padding: $gutter-width;
}
