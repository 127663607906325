.EmptyState {
  display: flex;
  align-items: center;
  justify-content: center;

  @include mq($until: desktop) {
    padding: $gutter-width;
  }

  @include mq($from: desktop) {
    padding: $gutter-width * 2;
  }
}

.EmptyState__content {
  max-width: 520px;
  font-size: 16px;

  background-color: #f9f9f9;
  border: 1px solid $border-color;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;

  @include mq($until: desktop) {
    padding: $gutter-width*0.5 $gutter-width;
  }

  @include mq($from: desktop) {
    padding: $gutter-width $gutter-width * 2;
  }
}
