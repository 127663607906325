.FilterToggle {
  @include reset-button;
  display: flex;
  align-items: center;
  width: 100%;
  line-height: 1;
  height: $header-height-mobile;

  @include mq($until: desktop) {
    border-top: 2px solid $border-color;
  }

  @include mq($from: desktop) {
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    padding-left: $gutter-width;
    padding-right: $gutter-width;
  }

  .FilterDropdown--isFirst & {
    @include mq($until: desktop) {
      border-top: none;
    }
  }
}

.FilterToggle__label {
  font-size: 16px;
}

.FilterToggle__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 43px;
  height: 43px;

  @media (hover: hover) {
    .FilterToggle:hover & {
      animation-name: hvr-hang-sink, hvr-hang;
      animation-duration: 0.3s, 1.5s;
      animation-delay: 0s, 0.3s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate;
    }
  }

  @media (hover: hover) {
    .FilterToggle.FilterToggle--isActive:hover & {
      animation-name: hvr-bob-float, hvr-bob;
      animation-duration: 0.3s, 1.5s;
      animation-delay: 0s, 0.3s;
      animation-timing-function: ease-out, ease-in-out;
      animation-iteration-count: 1, infinite;
      animation-fill-mode: forwards;
      animation-direction: normal, alternate;
    }
  }
}

.FilterToggle__count {
}
